<template>
  <div class="sg-test"></div>
</template>

<script>
export default {
  name: "Tempalte",
  props: {},
  data: () => {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-test {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to right, white, black, white);
  transition: all 0.5s ease-in-out;
  background-size: 100% 100%;
  background-position: top center;
}

.sg-test:hover {
  background-size: 200% 100%;
}

.sg-fade-in {
  &:hover {
    background-image: linear-gradient(
      to right,
      white 0%,
      #ebebeb 20%,
      #ebebeb 80%,
      white 100%
    );
    background-size: 200% 100%;
    width: 100%;
    animation: grayScaleBox 1s ease-out;
    background-position: top center;
  }
}

@keyframes grayScaleBox {
  0% {
    background-size: 70% 100%;
  }
  100% {
    background-size: 200% 100%;
  }
}
</style>
